//
// Variables
// --------------------------------------------------
//@import url(http://fonts.googleapis.com/css?family=Carrois+Gothic);
//@import url(http://fonts.googleapis.com/css?family=Source+Sans+Pro:6300,400,700,300italic,400italic,700italic);

// Fonts
// --------------------------------------------------
@font-face {
  font-family: 'Source Sans Pro';
  src: url("../fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf");
}
@font-face {
  font-family: 'Source Sans Pro';
  font-weight: bold;
  src: url("../fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf");
}
@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 300;
  src: url("../fonts/Source_Sans_Pro/SourceSansPro-Light.ttf");
}


// Global values
// --------------------------------------------------


// Grays
// -------------------------
@black:                 #000;
@grayDarker:            #222;
@grayDark:              #222;
@gray:                  #555;
@grayLight:             #999;
@grayLighter:           #e9e9e9;
@white:                 #fff;


// Accent colors
// -------------------------
@blue:                  #049cdb;
@blueDark:              #0064cd;
@green:                 #46a546;
@red:                   #9d261d;
@yellow:                #ffc40d;
@orange:                #f89406;
@pink:                  #c3325f;
@purple:                #7a43b6;

// Colour theme
// -------------------------
@color-a: rgb(19, 182, 234);
@color-b: rgb(108, 179, 63);
@color-c: rgb(66, 116, 99);
@color-d: rgb(98, 144, 129);
@color-e: rgb(205, 161, 141);
@color-f: rgb(136, 118, 98);
@color-g: rgb(249, 167, 29);

@color1:                @color-b;
@color1Dark:            darken(@color1, 10%);
@color1Light:           lighten(@color1, 10%);

@color2:                @color-g;
@color2Dark:            darken(@color2, 10%);
@color2Light:           lighten(@color2, 10%);

@color3:                rgb(136, 118, 98);
@color3Dark:            darken(@color3, 10%);
@color3Light:           lighten(@color3, 30%);
@color3Lighter:           lighten(@color3, 40%);

@mutedColor:            lighten(@color3, 40%);
@mutedColorLight:       lighten(@mutedColor, 5%);
@mutedColorDark:        lighten(@color3, 30%);
@darkColor:             #333;


@brand-primary:         @color1;

@body-bg:               white;
@content-background:    white;
@text-color:            @grayDark;

// Links
// -------------------------
@link-color:             @color1Dark;
@link-color-hover:        darken(@link-color, 15%);


// Typography
// -------------------------
@font-family-sans-serif:  Verdana, "Trebuchet MS", Helvetica, Arial, sans-serif;
@font-family-serif:       Georgia, "Times New Roman", Times, serif;
@font-family-headings:    "Source Sans Pro", "Trebuchet MS", sans-serif;

@font-size-base:          12px;


// Navbar
// -------------------------

// Basics of a navbar
@navbar-height:                    40px;
@navbar-margin-bottom:             0;
@navbar-inverse-bg:                rgba(0,0,0,0);
@navbar-inverse-border:            rgba(0,0,0,0);

@input-border-focus:               @color3;

@organization-dropdown-link-hover-bg:         #DDF1D3;

//// Disable responsive breakpoints
//@screen-xs-min: 1px;
//@screen-sm-min: 2px;
//@screen-md-mind: 3px;
//@screen-lg-min: 4px;




